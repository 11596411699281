.missing404 {
    background: black;
    font-family: 'Nanum Pen Script', cursive;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 10vh;
align-items: center;

}

#m404 {
    font-size: 50vh;
}

a,
a:hover,
a:visited,
a:active {
    color: white;
    cursor: pointer;
}
