#avilableSoon {
    font-family: 'Nanum Pen Script', cursive;   
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 6vw;
    word-wrap: break-word;
}

.projectCard{
    margin: 0% 0% 4% 0;
}

.carousel {
    height: auto;
    width: auto;
    overflow: hidden;
  }

.card-body{
    h4 {
        font-weight: bolder;
    }
    h5,h6 {
        font-weight: normal;
    }
}

.dflex-type{
    display: flex;
    justify-content: space-between;
}

h6.smallH6 {
    font-size: smaller;
}

.tech-span {
    border: 1px solid purple;
    padding: 2px 5px 2px 5px;
    margin: 2px;
    line-height: 30px;
}

.prj-info {   position: absolute;}
.prj-status{
    font-size: smaller;
    padding: 5px;
}

.att2 {
    // width: auto;
    height: 16rem;
    object-fit: contain;
}

video {
    height: 16rem;
    object-fit: contain;
}