body {
    overflow-x: hidden;
}

#placeholderForMenu {
    font-family: 'Nanum Pen Script', cursive;
    font-size: 23px;

    a {
        margin: 0 10px 0 10px;
        cursor: pointer;
    }
}