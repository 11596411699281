.SocialRow {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    margin-top: 5%;

    svg {
        // t r b l
        margin: 0 10px 0 10px;
    }

    a, a:visited, a:hover {
        color: white;
    }
}