@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Quicksand:wght@300;400;500;600;700&family=Inconsolata:wght@300;400;500;600;700&family=Nanum+Pen+Script&display=swap&display=swap');

/* font-family: 'IBM Plex Sans',
        sans-serif;
        font-family: 'Quicksand',
        sans-serif; */
.intro {
    display: flex;
    width: 100%;
    justify-content: center;

    /* font-family: 'IBM Plex Sans', sans-serif;*/
}
.homeCmp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
/*IMAGE SIZE*/
#profileImg {
    height: 200px;
    width: 200px;
}

.just-wrapper {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: flex-start;
}

.small-txt {
    font-size: 25px;

    // width: 100%;
    font-weight: normal;

}
.small-txt-2 {
    font-size: 25px;
    font-weight: normal;
    display: flex;
    flex-direction: row;
    align-self:flex-start !important;
    /* align-self: flex-start; */
    justify-content: flex-start;
}

.name {
    font-size: 50px;
    margin: 0;
}

.occup {
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
    width: 100%;
    bottom: 45%;

}

/* MOBILE TYPEWRITING ANIMATION */
#occupTypes {
    background-color: #131313;
    padding: 5px;
    flex-wrap: nowrap;
    font-family: 'Inconsolata', monospace;
    font-weight: lighter;
    // visibility: hidden;
    // display: none;
    visibility: visible;
    display: block;
}

@mixin random-bgr() {
    color: rgb(random(255), random(255), random(255));
}

.blink {
    animation: fade 1.2s infinite;
}

@keyframes fade {

    0%,
    100% {
        opacity: 0
    }

    50% {
      
        opacity: 1
    }
}

/* END MOBILE TYPEWRITING ANIMATION */

/* FLIP Animation FOR LARGE DEVICES */
#flip {
    height: 50px;
    overflow: hidden;
    background: #64379f;
}

#flip>div>div {
    color: #fff;
    padding: 4px 12px;
    height: 45px;
    margin-bottom: 45px;
    display: inline-block;

}

#flip div:first-child {
    animation: show 5s linear infinite;
}

@keyframes show {
    0% {
        margin-top: -270px;
    }

    5% {
        margin-top: -180px;
    }

    33% {
        margin-top: -180px;
    }

    38% {
        margin-top: -90px;
    }

    66% {
        margin-top: -90px;
    }

    71% {
        margin-top: 0px;
    }

    99.99% {
        margin-top: 0px;
    }

    100% {
        margin-top: -270px;
    }
}

/* END ANIMATION */

.bracketsAnimation {
    position: relative;
}

/* RESPONSIVE CODE */
@media only screen and (max-width: 900px) {

    .name,
    .occup,
    .intro {
        flex-direction: column;
    }
}

@media only screen and (max-width: 900px) {
    .name {
        font-size: 40px;
    }

    .occup {
        font-size: 27px;
        align-items: center !important;
    }

    .small-txt {
        font-size: 18px;
        display: flex;
        /* align-self: flex-start; */
        justify-content: center;
        width: 100%;
    }

    img {
        align-self: center;
    }
}


@media only screen and (max-width: 1250px) {
    .occup {
        flex-direction: column;
        align-items: flex-start;
    }
 }

/* END RESPONSIVE CODE */


