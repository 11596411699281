.sidebar {
    width: 100%;
    display: flex;
    
    button>svg {
        height: 50px;
        width: 50px;
        position: absolute;
        right: 0;
        margin: 13px 20px 0 0;
    }

    .menu-panel.active {
        visibility: visible;
        display: flex;
        opacity: 1;
    }
    .menu-panel.hidden {
        visibility: hidden;
        display: none;
        opacity: 0;
    }
    .menu-panel {
        position: absolute;
        z-index: 9999;
        background-color: white;
        height: 100%;
        width: 100%;
        left: 0;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        font-size: 60px;
        font-family: 'IBM Plex Sans', sans-serif;


        a,
        a:active,
        a:hover,
        a:visited {
            color: black;
            text-decoration: none;
            // text-transform: lowercase;
            cursor: pointer;
        }

        a {
            display: inline-block;
            position: relative;
            color: black;
        }

        a:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #64379f;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out ;
        }

        a:hover:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

    }
}



.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;
    z-index: 10000;
}

.line {
    fill: none;
    stroke: black;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
        stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

